import { isDev } from "@/helpers/env"

export function sessionFromCookie(pocketbase, cookie) {
  pocketbase.authStore.loadFromCookie(cookie || "")
}

export function sessionToCookie(app, pocketbase) {
  return pocketbase.authStore.exportToCookie({
    domain: (() => {
      if (isDev) {
        if (app === "ct") {
          return ".ct.test"
        }

        if (app === "ycd") {
          return ".ycd.test"
        }
      }

      if (app === "ct") {
        return ".commentstools.com"
      }

      if (app === "ycd") {
        return ".youtubecommentsdownloader.com"
      }
    })(),
    sameSite: "Lax",
  })
}
