import PocketBase from "pocketbase"
let pb

if (window.app === "ct") {
  pb = new PocketBase(import.meta.env.PUBLIC_CT_POCKETBASE_URL)
}

if (window.app === "ycd") {
  pb = new PocketBase(import.meta.env.PUBLIC_YCD_POCKETBASE_URL)
}

pb.autoCancellation(false)

export default pb
